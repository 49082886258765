import React from 'react'
import PropTypes from 'prop-types'
import { HEADING_LEVELS } from '../../misc/constants'
import { H1, H2, H3 } from './Heading.styled'

const isH1 = level => level === HEADING_LEVELS.H1
const isH2 = level => level === HEADING_LEVELS.H2

export default function Heading({
  level,
  color,
  children,
}) {
  if      (isH1(level)) return <H1 {...{color}}>{children}</H1>
  else if (isH2(level)) return <H2 {...{color}}>{children}</H2>
  else                  return <H3 {...{color}}>{children}</H3>
}

Heading.propTypes = {
  level: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
}
