import React from 'react'
import PropTypes from 'prop-types'
import { Anchor } from './Link.styled'

const Link = ({
  children,
  to,
  isBlank = false,
  className,
  color,
}) => (
  <Anchor
    href={to}
    target={isBlank ? '_blank' : '_self'}
    className={className}
    color={color}
  >
    {children}
  </Anchor>
)

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  to: PropTypes.string.isRequired,
  isBlank: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
}

Link.defaultProps = {
  isBlank: false,
  className: '',
}

export default Link