import React from 'react'
import { COLORS, HEADING_LEVELS } from '../../misc/constants'
import Heading from '../Heading'
import Button from '../Button'
import { BlogSection, Posts } from './Blog.styled'
import Post from './Post'
import Thumbnail1 from '../../images/blog/post-thumbnail-1.png'
import Thumbnail2 from '../../images/blog/post-thumbnail-2.png'
import ThumbnailWebp1 from '../../images/blog/post-thumbnail-1.webp'
import ThumbnailWebp2 from '../../images/blog/post-thumbnail-2.webp'
import { BUTTON_VARIANTS } from '../Button/Button'
import { isWebpNotSupported } from '../../misc/utils'

export const BLOG_DATA = [{
  thumbnailUrl: isWebpNotSupported() ? Thumbnail1 : ThumbnailWebp1,
  title: 'VentureDevs Product Discovery Bootcamp 2020',
  description: 'To design award-winning products and build industry-leading teams, VentureDevs follows a proprietary software development playbook that has worked again and again. A critical component of that playbook is what we call product discovery, and no VentureDevs product management process is complete without it.',
  postUrl: 'https://venturedevs.com/pl/blog/venturedevs-product-discovery-bootcamp-2020/',
}, {
  thumbnailUrl: isWebpNotSupported() ? Thumbnail2 : ThumbnailWebp2,
  title: 'Product Discovery: Set Up Your Business For Market Success',
  description: 'Every product starts with a great idea, but getting that idea off the ground is easier said than done. To bring your idea to life — and to transform or grow your business in the process — you need to make sure that you, your team, and your external partners have done your research, planned ahead, and accounted for potential risks.',
  postUrl: 'https://venturedevs.com/pl/blog/product-discovery-set-your-business-market-success/',
}]

export default function Blog() {
  return (
    <BlogSection>
      <Heading
        level={HEADING_LEVELS.H1}
        color={COLORS.DARK}
      >
        The VentureDevs Blog
      </Heading>

      <Posts>
        {BLOG_DATA.map(({ thumbnailUrl, title, description, postUrl }) => (
          <Post
            key={title}
            {...{ thumbnailUrl, title, description, postUrl }}
          />
        ))}
      </Posts>

      <Button
        isBlank
        isLink
        variant={BUTTON_VARIANTS.SECONDARY}
        url="https://venturedevs.com/pl/blog/"
      >
        View all blog posts
      </Button>
    </BlogSection>
  )
}
