import React, { useState } from 'react'
import { COLORS, EMAIL_PATTERN, HEADING_LEVELS, RESULT_CODE } from '../../misc/constants'
import Button from '../Button'
import Heading from '../Heading'
import Link from '../Link'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {
  BackgroundDecoration,
  Form,
  StayUpdatedSection,
  ElementsHolder,
  Text,
  Clause,
  ResponseText,
} from './StayUpdated.styled'
import InputWithFloatingLabel from '../InputWithFloatingLabel'

export default function StayUpdated() {
  const [email, setEmail] = useState('')
  const [hasError, setHasError] = useState(false)
  const [response, setResponse] = useState({ msg: '', result: '' })
  
  async function handleSubmit(event) {
    event.preventDefault()
    setHasError(!EMAIL_PATTERN.test(email))
    const result = await addToMailchimp(email)
    setResponse(result)
  }

  function handleChange(value) {
    setEmail(value)
    setHasError(!EMAIL_PATTERN.test(value))
  }

  return (
    <StayUpdatedSection>
      <ElementsHolder>
        <BackgroundDecoration />
        <Form
          noValidate
          onSubmit={handleSubmit}
        >
          <Heading
            level={HEADING_LEVELS.H1}
            color={COLORS.DARK}
          >
            Newsletter
          </Heading>
          <Text>
            Subscribe to our newsletter and stay up to date with all the news!
          </Text>
          {response.result === RESULT_CODE.SUCCESS ? (
            <ResponseText>{response.msg}</ResponseText>
          ) : (
            <>
              <InputWithFloatingLabel
                label="Email address"
                onChange={handleChange}
                type="email"
                pattern={EMAIL_PATTERN}
                hasError={hasError}
              />
              <Clause>
                *By subscribing to the newsletter list, you accept the <Link to="https://venturedevs.com/en/terms-and-conditions/" isBlank color={COLORS.ORANGE}>regulations</Link>!
              </Clause>
              <Button disabled={hasError} type="submit">Subscribe</Button>
            </>
          )}
        </Form>
      </ElementsHolder>
    </StayUpdatedSection>
  )
}
