import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Label,
  ErrorMessage,
  Textarea,
} from './TextareaWithFloatingLabel.styled'

export default function TextareaWithFloatingLabel({
  required,
  label,
  onChange,
  onBlur,
  hasError,
}) {
  const [textareaValue, setTextareaValue] = useState('')

  function handleChange({
    target: { value }
  }) {
    setTextareaValue(value)
    onChange(value)
  }

  return (
    <Wrapper>
      <Label isVisible={textareaValue !== ''}>
        {label}
      </Label>

      <Textarea
        placeholder={label}
        onChange={handleChange}
        onBlur={onBlur}
        required={required}
      />

      <ErrorMessage isVisible={hasError}>
        This field cannot be empty
      </ErrorMessage>
    </Wrapper>
  )
}

TextareaWithFloatingLabel.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
}

TextareaWithFloatingLabel.defaultProps = {
  required: false,
  onBlur: () => null,
}