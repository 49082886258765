import styled, { css } from 'styled-components'
import { FONT_SIZES, FONT_WEIGHTS, OFFSETS, COLORS, BP } from '../../../../misc/constants'
import { typography } from '../../../../misc/typography'
import Link from '../../../Link'

export const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  margin: ${OFFSETS[30]} 0 ${OFFSETS[20]};

  @media (max-width: ${BP[1024]}) {
    flex-direction: column;
  }
`

export const Member = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${OFFSETS[30]};
`

export const Name = styled.h3`
  ${typography(FONT_SIZES[18], FONT_WEIGHTS.SEMI_BOLD, COLORS.WHITE)}
  margin-bottom: ${OFFSETS[30]};
`

const commonTextStyles = () => css`
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.THIN, COLORS.WHITE)};
  margin: 0;
  padding-bottom: ${OFFSETS[8]};
  letter-spacing: 0;
`

export const Role = styled.h4`
  ${commonTextStyles()};
`

export const Phone = styled(Link)`
  ${commonTextStyles()};
`

export const Mail = styled(Link)`
  ${commonTextStyles()};
`