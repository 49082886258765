import styled from 'styled-components'
import { OFFSETS, BP } from '../../misc/constants'
import { Section } from '../layoutComponents'

export const CooperationSection = styled(Section).attrs({
  'data-section': 'cooperation'
})`
  padding: 0 ${OFFSETS[18]} ${OFFSETS[96]};

  @media (max-width: ${BP[480]}) {
    padding: 0 ${OFFSETS[18]} ${OFFSETS[48]};
  }
`