import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {
  COLORS,
  EMAIL_PATTERN,
  HEADING_LEVELS,
  MAILCHIMP_REGISTER_ENDPOINT,
  RESULT_CODE,
} from '../../misc/constants'
import Heading from '../Heading'
import PlayButton from '../SVGs/PlayButton'
import RegisterButton from '../SVGs/RegisterButton'
import Agenda from './Agenda'
import Avatar from './Avatar'
import Bio from './Bio'
import {
  Body,
  Description,
  Edition,
  Header,
  EventWrapper,
  TopWrapper,
  BottomWrapper,
  RegisterBtnWrapper,
  ResponseText,
  ResponseWrapper,
} from './Event.styled'
import EventDate from './EventDate'
import Info from './Info'
import PastEventsHeader from './PastEventsHeader'
import RegisterForm from './RegisterForm'

export const EVENT_TYPES = {
  UPCOMING: 'upcoming',
  PAST: 'past',
}

function isUpcomingEvent(date) {
  const now = new Date()
  const eventDate = date
  return eventDate.getTime() - now.getTime() > 0
}

export default function Event({ data }) {
  const [isRegisterVisible, setIsRegisterVisible] = useState(false)
  const [email, setEmail] = useState('')
  const [hasEmailError, setHasEmailError] = useState(false)
  const [name, setName] = useState('')
  const [hasNameError, setHasNameError] = useState(false)
  const [textarea, setTextarea] = useState('')
  const [hasTextareaError, setHasTextareaError] = useState(false)
  const [isMarketingConsentChecked, setIsMarketingConsentChecked] = useState(false)
  const [isPersonalDataConsentChecked, setIsPersonalDataConsentChecked] = useState(false)
  const [hasMarketingConsentError, setHasMarketingConsentError] = useState(false)
  const [hasPersonalDataConsentError, setHasPersonalDataConsentError] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)
  const registerWrapperRef = useRef(null)
  const [response, setResponse] = useState({ msg: '', result: '' })
  const {
    edition,
    date,
    title,
    description,
    agenda,
    speaker,
    speakerAvatarUrl,
    speakerBio,
    registerUrl,
    videoUrl
  } = data
  
  const isUpcoming = isUpcomingEvent(date)
  const headingColor = isUpcoming ? COLORS.DARK : COLORS.WHITE

  function expandRegisterWrapper() {
    const element = registerWrapperRef.current
    const { style } = element
    const elementHeight = element.scrollHeight
    const elementTransition = style.transition
    style.transition = ''
    
    requestAnimationFrame(() => {
      style.height = `${0}rem`
      
      requestAnimationFrame(() => {
        style.transition = elementTransition
        style.height = `${elementHeight / 10}rem`
      })
    })
  }

  function handleRegisterClick(event) {
    if (!isRegisterVisible) {
      setIsRegisterVisible(true)
      expandRegisterWrapper()
    } else {
      handleSubmit(event)
    }
  }

  function isFormValid() {
    return EMAIL_PATTERN.test(email) &&
      name !== '' &&
      textarea !== '' &&
      isMarketingConsentChecked &&
      isPersonalDataConsentChecked
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setHasEmailError(!EMAIL_PATTERN.test(email))
    setHasNameError(name === '')
    setHasTextareaError(textarea === '')
    setHasMarketingConsentError(!isMarketingConsentChecked)
    setHasPersonalDataConsentError(!isPersonalDataConsentChecked)
    setIsFormTouched(true)
    if (isFormValid()) {
      const result = await addToMailchimp(
        email,
        { NAME: name, SPEC: textarea, },
        MAILCHIMP_REGISTER_ENDPOINT
      )
      setResponse(result)
    }
  }

  function handleOnNameBlur() {
    setHasNameError(name === '')
    setIsFormTouched(true)
  }

  function handleOnEmailBlur() {
    setHasEmailError(!EMAIL_PATTERN.test(email))
    setIsFormTouched(true)
  }

  function handleOnNameChange(value) {
    setName(value)
    setHasNameError(value === '')
    setIsFormTouched(true)
  }

  function handleOnEmailChange(value) {
    setEmail(value)
    setHasEmailError(!EMAIL_PATTERN.test(value))
    setIsFormTouched(true)
  }

  function handleOnMarketingConsentClick(event) {
    setIsMarketingConsentChecked(event.target.checked)
    setHasMarketingConsentError(!event.target.checked)
    setIsFormTouched(true)
  }

  function handleOnPersonalDataConsentClick(event) {
    setIsPersonalDataConsentChecked(event.target.checked)
    setHasPersonalDataConsentError(!event.target.checked)
    setIsFormTouched(true)
  }

  function handleOnPersonalDataConsentBlur() {
    if (!isPersonalDataConsentChecked) {
      setHasPersonalDataConsentError(true)
    }
    setIsFormTouched(true)
  }

  function handleOnMarketingConsentBlur() {
    if (!isMarketingConsentChecked) {
      setHasMarketingConsentError(true)
    }
    setIsFormTouched(true)
  }

  function handleOnTextareaChange(value) {
    setTextarea(value)
    setHasTextareaError(value === '')
    setIsFormTouched(true)
  }

  function handleOnTextareaBlur() {
    setHasTextareaError(textarea === '')
    setIsFormTouched(true)
  }

  
  const isRegisterButtonDisabled = isFormTouched && !isFormValid()
  
  return (
    <>
      <EventWrapper {...{ isUpcoming }}>
        <TopWrapper>
          <Header>
            <Edition>#Venturemeetups {edition}</Edition>
            <EventDate {...{ date, isUpcoming }} />
          </Header>

          <Heading
            level={HEADING_LEVELS.H2}
            color={headingColor}
          >
            {title}
          </Heading>

          <Body>
            <Description {...{ isUpcoming }}>
              {description}
            </Description>
            {isUpcoming ? (
              <>
                <Agenda data={agenda} />
                <Info />
              </>
            ) : (
              <PlayButton url={videoUrl} />
            )}
          </Body>
        </TopWrapper>

        <BottomWrapper {...{ isUpcoming }}>
          <Avatar url={speakerAvatarUrl} />
          <Bio {...{ speaker, speakerBio, isUpcoming }} />
        </BottomWrapper>
        {isUpcoming && (
          <>
            {response.result === RESULT_CODE.SUCCESS ? (
              <ResponseWrapper>
                <ResponseText>{response.msg}</ResponseText>
              </ResponseWrapper>
            ) : (
            <>
              <RegisterForm
                onEmailChange={handleOnEmailChange}
                onNameChange={handleOnNameChange}
                onNameBlur={handleOnNameBlur}
                onEmailBlur={handleOnEmailBlur}
                onMarketingConsentClick={handleOnMarketingConsentClick}
                onPersonalDataConsentClick={handleOnPersonalDataConsentClick}
                personalDataConsentChecked={isPersonalDataConsentChecked}
                marketingConsentChecked={isMarketingConsentChecked}
                onPersonalDataConsentBlur={handleOnPersonalDataConsentBlur}
                onMarketingConsentBlur={handleOnMarketingConsentBlur}
                onTextareaChange={handleOnTextareaChange}
                onTextareaBlur={handleOnTextareaBlur}
                {...{
                  hasNameError,
                  hasEmailError,
                  hasPersonalDataConsentError,
                  hasMarketingConsentError,
                  hasTextareaError
                }}
                ref={registerWrapperRef}
              />

              <RegisterBtnWrapper>
                <RegisterButton disabled={isRegisterButtonDisabled} onClick={handleRegisterClick} url={registerUrl} />
              </RegisterBtnWrapper>
            </>
          )}
          </>
        )}
      </EventWrapper>

      {isUpcoming && <PastEventsHeader />}  
    </>
  )
}

Event.propTypes = {
  data: PropTypes.shape({
    edition: PropTypes.number.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    agenda: PropTypes.arrayOf(PropTypes.shape({
      time: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })).isRequired,
    speaker: PropTypes.string.isRequired,
    speakerAvatarUrl: PropTypes.string.isRequired,
    speakerBio: PropTypes.string.isRequired,
    registerUrl: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
  }).isRequired,
}