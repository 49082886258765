import React from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from './Checkbox.styled'

export default function Checkbox({
  children,
  className,
  checked,
  onChange,
  hasError,
  required,
  onBlur,
}) {
  return (
    <Label {...{ hasError, className }}>
      <HiddenCheckbox {...{ onChange, onBlur, required, checked }} />
      <StyledCheckbox {...{ checked }}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <span>
        {children}
      </span>
    </Label>
  )
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
}

Checkbox.defaultProps = {
  required: false,
  onBlur: () => null,
  className: '',
}