import React, { forwardRef } from 'react'
import { COLORS, EMAIL_PATTERN, OFFSETS, ROUTES } from '../../../misc/constants'
import {
  RegisterWrapper,
  Form,
  Box,
  Row,
} from './RegisterForm.styled'
import InputWithFloatingLabel from '../../InputWithFloatingLabel'
import Spacer from '../../Spacer/Spacer'
import Checkbox from '../../Checkbox'
import TextareaWithFloatingLabel from '../../TextareaWithFloatingLabel'
import Link from '../../Link'

const RegisterForm = forwardRef(({
  onEmailChange,
  onNameChange,
  onNameBlur,
  onEmailBlur,
  hasNameError,
  hasEmailError,
  hasMarketingConsentError,
  onMarketingConsentClick,
  onMarketingConsentBlur,
  hasPersonalDataConsentError,
  onPersonalDataConsentClick,
  onPersonalDataConsentBlur,
  personalDataConsentChecked,
  marketingConsentChecked,
  onTextareaChange,
  onTextareaBlur,
  hasTextareaError,
}, ref) => (
  <RegisterWrapper ref={ref}>
    <Form noValidate>
      <Row>
        <Box>
          <InputWithFloatingLabel
            label="Name*"
            type="name"
            onChange={onNameChange}
            onBlur={onNameBlur}
            hasError={hasNameError}
          />

          <InputWithFloatingLabel
            label="Email*"
            type="email"
            onChange={onEmailChange}
            onBlur={onEmailBlur}
            hasError={hasEmailError}
            pattern={EMAIL_PATTERN}
          />
        </Box>

        <Spacer width={OFFSETS[30]} />
        
        <Box>
          <TextareaWithFloatingLabel
            onChange={onTextareaChange}
            onBlur={onTextareaBlur}
            hasError={hasTextareaError}
            label="What is your specialization?*"
          />
        </Box>
      </Row>

      <Row>
        <Checkbox
          onChange={onPersonalDataConsentClick}
          checked={personalDataConsentChecked}
          onBlur={onPersonalDataConsentBlur}
          hasError={hasPersonalDataConsentError}
          required
        >
          Consent to the processing of personal data:*
          <br />
          <Link
            color={COLORS.ORANGE}
            isBlank
            to={ROUTES.CONSENTS}
          >
            Read more
          </Link>
        </Checkbox>

        <Spacer width={OFFSETS[30]} />
        
        <Checkbox
          checked={marketingConsentChecked}
          onChange={onMarketingConsentClick}
          onBlur={onMarketingConsentBlur}
          hasError={hasMarketingConsentError}
          required
        >

          Consent to receive commercial information:*
          <br />
          <Link
            color={COLORS.ORANGE}
            isBlank
            to={ROUTES.CONSENTS}
          >
            Read more
          </Link>
        </Checkbox>
      </Row>
    </Form>
  </RegisterWrapper>
))

export default RegisterForm