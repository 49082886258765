import styled from 'styled-components'
import { COLORS, INNER_CONTAINER_WIDTH, BP } from '../../misc/constants'
import { Section } from '../layoutComponents'

export const BlogSection = styled(Section).attrs({
  'data-section': 'blog'
})`
  background: ${COLORS.WHITE};
`

export const Posts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${INNER_CONTAINER_WIDTH};
  width: 100%;
  margin: 6rem 0;

  @media screen and (max-width: ${BP[1200]}) {
    flex-direction: column;
  }

  @media screen and (max-width: ${BP[480]}) {
    margin: 4rem 0 3rem;
  }
`
