import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '../../../../misc/constants'

const Youtube = ({ fill = COLORS.WHITE }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 2.06982C0.1548 2.06982 0 2.85642 0 8.99982C0 15.1432 0.1548 15.9298 9 15.9298C17.8452 15.9298 18 15.1432 18 8.99982C18 2.85642 17.8452 2.06982 9 2.06982ZM11.8845 9.30042L7.8435 11.1868C7.4898 11.3506 7.2 11.167 7.2 10.7764V7.22322C7.2 6.83352 7.4898 6.64902 7.8435 6.81282L11.8845 8.69922C12.2382 8.86482 12.2382 9.13482 11.8845 9.30042Z" fill={fill}/>
  </svg>
)

Youtube.propTypes = {
  fill: PropTypes.string,
}

Youtube.defaultProps = {
  fill: COLORS.WHITE,
}

export default Youtube