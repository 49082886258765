import styled, { css } from 'styled-components'
import { transition } from '../../misc/utils'
import { typography } from '../../misc/typography'
import { OFFSETS, COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../misc/constants'

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  background: ${({ checked }) => checked ? COLORS.ORANGE : COLORS.WHITE};
  border-radius: 0.3rem;
  ${transition(['all'])};
  margin-right: ${OFFSETS[8]};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0.1rem ${COLORS.ORANGE};
  }

  ${Icon} {
    visibility: ${({ checked }) => checked ? 'visible' : 'hidden'}
  }
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  padding: ${OFFSETS[4]};
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.REGULAR, COLORS.WHITE)}
  ${({ hasError }) => hasError && css`
    color: ${COLORS.RED};
  `};
`
