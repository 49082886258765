import styled from 'styled-components'
import { COLORS, FONT_SIZES, FONT_WEIGHTS, OFFSETS, BP } from '../../../misc/constants'
import { typography } from '../../../misc/typography'

export const Wrapper = styled.div`
  ${({ isUpcoming }) => {
    const textColor = isUpcoming ? COLORS.WHITE : COLORS.DARK_GREY
    return typography(FONT_SIZES[12], FONT_WEIGHTS.SEMI_BOLD, textColor)
  }}
  height: 4.2rem;
  background: ${({ isUpcoming }) => isUpcoming
    ? COLORS.VIOLET
    : 'transparent'
  };
  display: flex;
  align-items: center;
  border-top-left-radius: ${OFFSETS[48]};
  border-bottom-left-radius: ${OFFSETS[48]};
  padding: 0 ${OFFSETS[20]};

  @media (max-width: ${BP[480]}) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: ${OFFSETS[48]};
    border-bottom-right-radius: ${OFFSETS[48]};
    transform: translateX(-2rem);
    margin: 0 0 ${OFFSETS[20]};
  }
`

export const StartHour = styled.span`
  background: ${COLORS.ORANGE};
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.THIN, COLORS.WHITE)};
  border-radius: ${OFFSETS[48]};
  padding: ${OFFSETS[4]} ${OFFSETS[8]};
  margin-left: ${OFFSETS[8]};
`