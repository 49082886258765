import React, { useLayoutEffect, useRef } from 'react'
import { COLORS, OFFSETS, SOCIAL_URLS } from '../../misc/constants'
import { isWebpNotSupported } from '../../misc/utils'
import { isSmartphoneScreenWidth } from '../../misc/mobile'
import Button from '../Button'
import { BUTTON_VARIANTS } from '../Button/Button'
import Spacer from '../Spacer/Spacer'
import { Instagram } from '../SVGs/Icons'
import { GallerySection, Photo, PhotoGrid } from './Gallery.styled'

import galleryItem1 from '../../images/gallery/pic-1.png'
import galleryItem2 from '../../images/gallery/pic-2.png'
import galleryItem3 from '../../images/gallery/pic-3.png'
import galleryItem4 from '../../images/gallery/pic-4.png'
import galleryItem5 from '../../images/gallery/pic-5.png'
import galleryItem6 from '../../images/gallery/pic-6.png'
import galleryItem7 from '../../images/gallery/pic-7.png'
import galleryItem8 from '../../images/gallery/pic-8.png'

import galleryWebpItem1 from '../../images/gallery/pic-1.webp'
import galleryWebpItem2 from '../../images/gallery/pic-2.webp'
import galleryWebpItem3 from '../../images/gallery/pic-3.webp'
import galleryWebpItem4 from '../../images/gallery/pic-4.webp'
import galleryWebpItem5 from '../../images/gallery/pic-5.webp'
import galleryWebpItem6 from '../../images/gallery/pic-6.webp'
import galleryWebpItem7 from '../../images/gallery/pic-7.webp'
import galleryWebpItem8 from '../../images/gallery/pic-8.webp'

const IMAGES = [{
  src: isWebpNotSupported() ? galleryItem1 : galleryWebpItem1,
  alt: ''
}, {
  src: isWebpNotSupported() ? galleryItem2 : galleryWebpItem2,
  alt: ''
}, {
  src: isWebpNotSupported() ? galleryItem3 : galleryWebpItem3,
  alt: ''
}, {
  src: isWebpNotSupported() ? galleryItem4 : galleryWebpItem4,
  alt: ''
}, {
  src: isWebpNotSupported() ? galleryItem5 : galleryWebpItem5,
  alt: ''
}, {
  src: isWebpNotSupported() ? galleryItem6 : galleryWebpItem6,
  alt: ''
}, {
  src: isWebpNotSupported() ? galleryItem7 : galleryWebpItem7,
  alt: ''
}, {
  src: isWebpNotSupported() ? galleryItem8 : galleryWebpItem8,
  alt: ''
}]


export default function Gallery() {
  let photoGridRef = useRef(null)
  const isMobile = isSmartphoneScreenWidth

  useLayoutEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        const offsetToScroll = photoGridRef?.current?.scrollLeft
        photoGridRef?.current?.scrollBy(-offsetToScroll, 0)
      }, 100)
    }
  }, [photoGridRef, isMobile])

  return (
    <GallerySection>
      <PhotoGrid ref={photoGridRef}>
        {IMAGES.map(({ src, alt }) => (
          <Photo key={src} {...{ src, alt }} />
        ))}
      </PhotoGrid>
      <Button
        isLink
        isBlank
        variant={BUTTON_VARIANTS.SECONDARY}
        url={SOCIAL_URLS.INSTAGRAM}
      >
        <Instagram fill={COLORS.DARK} />
        <Spacer width={OFFSETS[8]} />
        Follow us on @venturedevs
      </Button>
    </GallerySection>
  )
}
