import React from 'react'
import PropTypes from 'prop-types'
import { Box } from './Spacer.styled'

const Spacer = ({
  width = 'auto',
  height = 'auto',
  isBlock = false,
}) => <Box {...{ width, height, isBlock }} />

Spacer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  isBlock: PropTypes.bool,
}

Spacer.defaultProps = {
  width: 'auto',
  height: 'auto',
  isBlock: false,
}

export default Spacer