import { OFFSETS } from '../../../misc/constants'
import styled, { css } from 'styled-components'
import mask from '../../../images/avatar/mask.svg'
import triangle from '../../../images/avatar/triangle.svg'

export const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 10rem;
  height: 10rem;
  margin-right: ${OFFSETS[18]};
  background-image: url(${triangle});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

export const Image = styled.div`
  width: 11rem;
  height: 11rem;
  ${({ url }) => url && css`
    background-image: url(${url});
  `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  mask-image: url(${mask});
  mask-repeat: no-repeat;
  mask-position: center center;

  position: absolute;
  top: 43%;
  left: 57%;
  transform: translate(-50%, -50%);
`

export const BackgroundTriangle = styled.svg``