import { transition } from '../../../misc/utils'
import styled from 'styled-components'
import { OFFSETS, COLORS, BP } from '../../../misc/constants'
import { commonInputStyles } from '../../InputWithFloatingLabel/InputWithFloatingLabel.styled'

export const RegisterWrapper = styled.div`
  height: 0;
  ${transition(['height'])};
  overflow: hidden;
  background: ${COLORS.SLIGHTLY_DARKER_DARK};
`

export const Form = styled.form`
  padding: ${OFFSETS[48]} ${OFFSETS[20]};
  max-width: 80rem;
  margin: 0 auto;
`

export const Textarea = styled.textarea`
  resize: none;
  height: 13rem;
  ${commonInputStyles};
`

export const Box = styled.div`
  flex-grow: 1;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: ${BP[480]}) {
    flex-direction: column;
  }
`