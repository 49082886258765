import React from 'react'
import PropTypes from 'prop-types'
import { scrollTo } from '../../misc/utils'
import { BUTTON_VARIANTS } from '../Button/Button'
import { ArrowUp } from './ScrollUp.styled'

export default function ScrollUp({ isVisible }) {
  function handleClick() {
    scrollTo('[data-section="top"]')
  }

  return (
    <ArrowUp
      variant={BUTTON_VARIANTS.GHOST}
      isVisible={isVisible}
      onClick={handleClick}
    >
      ↑
    </ArrowUp>
  )
}

ScrollUp.propTypes = {
  isVisible: PropTypes.bool.isRequired,
}
