import { BP } from './constants'
import { isBrowser } from './utils'

export const isSmartphoneScreenWidth = () => {
  if (isBrowser) return false
  return !!window.matchMedia(`(max-width: ${BP[767]}px)`).matches
}

export const isAboveSmartphoneScreenWidth = () => {
  if (isBrowser) return false
  return !!window.matchMedia(`(min-width: ${BP[768]}px)`).matches
}

export const isDesktop = () => {
  if (isBrowser) return false
  return !!window.matchMedia(`(min-width: ${BP[1200]}px)`).matches
}
