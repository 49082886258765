import React from 'react'
import PropTypes from 'prop-types'
import { COLORS, OFFSETS } from '../../../misc/constants'
import Spacer from '../../Spacer/Spacer'
import { Chevron } from '../../SVGs/Icons'
import {
  Description,
  PostWrapper,
  ReadMore,
  TextWrapper,
  Thumbnail,
  Title
} from './Post.styled'

const Post = ({
  thumbnailUrl,
  title,
  description,
  postUrl,
}) => {
  return (
    <PostWrapper>
      <Thumbnail src={thumbnailUrl} />
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ReadMore
          color={COLORS.ORANGE}
          isBlank
          to={postUrl}
        >
          Read more
          <Spacer width={OFFSETS[8]} />
          <Chevron />
        </ReadMore>
      </TextWrapper>
    </PostWrapper>
  )
}

Post.propTypes = {
  thumbnailUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
}

export default Post