import styled from 'styled-components'
import { COLORS, FONT_SIZES, FONT_WEIGHTS, OFFSETS, BP } from '../../../misc/constants'
import { typography } from '../../../misc/typography'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Speaker = styled.h5`
  ${({ isUpcoming }) => {
    const speakerColor = isUpcoming ? COLORS.DARK : COLORS.WHITE
    return typography(FONT_SIZES[14], FONT_WEIGHTS.THIN, speakerColor)
  }};
  margin: 0 0 ${OFFSETS[8]};
  flex-grow: 1;
  
  @media (max-width: ${BP[480]}) {
    font-size: ${FONT_SIZES[12]};
  }
`

export const Highlight = styled.span`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  text-transform: uppercase;
`

export const Text = styled.p`
  ${({ isUpcoming }) => {
    const textColor = isUpcoming ? COLORS.DARK : COLORS.WHITE
    return typography(FONT_SIZES[14], FONT_WEIGHTS.THIN, textColor, '1.6')
  }};

  @media (max-width: ${BP[480]}) {
    font-size: ${FONT_SIZES[10]};
  }
`
