import styled from 'styled-components'
import { OFFSETS, BP, INNER_CONTAINER_WIDTH, COLORS } from '../misc/constants'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem ${OFFSETS[18]};

  @media screen and (max-width: ${BP[480]}) {
    padding: 6rem ${OFFSETS[18]};
  }
`

export const MainWrapper = styled.main.attrs({
  'data-section': 'top'
})``

export const TextPagesWrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${INNER_CONTAINER_WIDTH};
  margin: ${OFFSETS[96]} auto;
  padding: ${OFFSETS[96]} ${OFFSETS[18]};
  color: ${COLORS.WHITE};
`
