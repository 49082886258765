import styled from 'styled-components'
import { COLORS, FONT_SIZES, FONT_WEIGHTS, OFFSETS } from '../../../misc/constants'
import { typography } from '../../../misc/typography'

export const Wrapper = styled.div`
  margin: 0 ${OFFSETS[30]} ${OFFSETS[18]} 0;
  width: 27rem;
`

export const Subheading = styled.h5`
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.REGULAR, COLORS.DARK)};
  margin: 0 0 1rem;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const Item = styled.li`
  border-radius: ${OFFSETS[48]};
  background-color: ${COLORS.GREY};
  padding: 0 ${OFFSETS[8]};
  height: 2.5rem;
  width: max-content;
  &:not(:last-child) {
    margin-bottom: ${OFFSETS[8]};
  }
`

export const Time = styled.span`
  ${typography(FONT_SIZES[10], FONT_WEIGHTS.BOLD, COLORS.DARK)}
`

export const Text = styled.span`
  ${typography(FONT_SIZES[10], FONT_WEIGHTS.THIN, COLORS.DARK)}
`