import styled from 'styled-components'
import { rgba } from 'polished'
import { COLORS, FONT_SIZES, FONT_WEIGHTS, BP } from '../../misc/constants'
import { typography } from '../../misc/typography'
import { Section } from '../layoutComponents'
import footerBackground from '../../images/footer/footer-background.png'
import footerBackgroundMobile from '../../images/footer/footer-background-mobile.png'

export const FooterSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${footerBackground});
  background-color: ${COLORS.CHARCOAL};
  background-size: cover;
  background-repeat: no-repeat;
  color: ${COLORS.WHITE};

  @media (max-width: ${BP[1024]}) {
    background-image: url(${footerBackgroundMobile});
  }
`

export const Copyright = styled.div`
  ${typography(
    FONT_SIZES[12],
    FONT_WEIGHTS.THIN,
    rgba(COLORS.WHITE, 0.4)
  )};
`