import styled, { css } from 'styled-components'
import { BUTTON_COMMON_ACTIVE, BUTTON_COMMON_HOVER } from '../../../misc/constants'
import { transition } from '../../../misc/utils'

const commonFilter = () => css`
  filter: drop-shadow(0 0 .5rem #EC5839);
`

export const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Triangle = styled.path`
  ${transition(['fill-opacity', 'filter'])};
`

export const InnerGroup = styled.g`
  ${transition(['transform'])};
  transform-origin: center center;
`

export const Svg = styled.svg`
  &:hover {
    ${Triangle} {
      fill-opacity: 0.3;
      ${commonFilter};
    }

    ${InnerGroup} {
      ${BUTTON_COMMON_HOVER};
    }
  }

  &:active {
    ${Triangle} {
      fill-opacity: 0.5;
      ${commonFilter};
    }

    ${InnerGroup} {
      ${BUTTON_COMMON_ACTIVE};
    }
  }
`
