import React from 'react'
import PropTypes from 'prop-types'
import { Anchor, InnerGroup, Svg, Triangle } from './PlayButton.styled'

const PlayButton = ({ url }) => (
  <Anchor href={url} target="_blank" title="Watch on Youtube in a new tab">
    <Svg width="170" height="184" viewBox="0 0 170 184" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Triangle d="M52.0436 175.063C32.0436 186.61 7.04365 172.176 7.04366 149.082L7.04366 34.9179C7.04367 11.8239 32.0437 -2.60988 52.0437 8.93712L150.913 66.0192C170.913 77.5663 170.913 106.434 150.913 117.981L52.0436 175.063Z" fill="#EC5839" fillOpacity="0.2"/>
      <InnerGroup clipPath="url(#clip0)">
        <path d="M101.207 76.4827C98.6723 71.5664 94.9756 67.2252 90.5172 63.9282C89.8628 63.4445 88.9406 63.5827 88.4567 64.2368C87.9727 64.8909 88.1112 65.8134 88.7653 66.2973C92.8625 69.3272 96.2592 73.3161 98.5886 77.8331C101.029 82.5663 102.267 87.6859 102.267 93.0502C102.267 111.384 87.3506 126.3 69.0164 126.3C50.6823 126.3 35.7662 111.384 35.7662 93.05C35.7662 74.7156 50.6823 59.7998 69.0164 59.7998C69.8299 59.7998 70.4896 59.14 70.4896 58.3266C70.4896 57.5131 69.8299 56.8534 69.0164 56.8534C49.0576 56.8534 32.8198 73.0912 32.8198 93.05C32.8198 113.009 49.0576 129.247 69.0164 129.247C88.9752 129.247 105.213 113.009 105.213 93.05C105.213 87.2937 103.828 81.565 101.207 76.4827Z" fill="#EC5839"/>
        <path d="M59.7545 76.2428C59.2938 76.5048 59.0093 76.9939 59.0093 77.5235V109.854C59.0093 110.667 59.669 111.327 60.4825 111.327C61.2959 111.327 61.9557 110.667 61.9557 109.854V80.1151L83.767 93.0996L67.7279 103.433C67.0441 103.874 66.8467 104.786 67.2874 105.469C67.7284 106.154 68.6398 106.351 69.3238 105.91L87.3619 94.2882C87.7906 94.012 88.0459 93.5337 88.0368 93.0237C88.0277 92.5137 87.7557 92.0448 87.3174 91.7838L61.236 76.2575C60.7803 75.9867 60.2151 75.9808 59.7545 76.2428Z" fill="#EC5839"/>
      </InnerGroup>
      <defs>
        <clipPath id="clip0">
          <rect width="72.3934" height="72.3934" fill="white" transform="translate(32.8198 56.8534)"/>
        </clipPath>
      </defs>
    </Svg>
  </Anchor>
)

PlayButton.propTypes = {
  url: PropTypes.string.isRequired,
}

export default PlayButton