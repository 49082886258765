import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Subheading,
  List,
  Item,
  Time,
  Text,
} from './Agenda.styled'

const Agenda = ({ data }) => (
  <Wrapper>
    <Subheading>Agenda:</Subheading>
    <List>
      {data.map(({ time, text }) => (
        <Item key={text}>
          <Time>{time}</Time>
          <Text> - {text}</Text>
        </Item>
      ))}
    </List>
  </Wrapper>
)

Agenda.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    time: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
}

export default Agenda