import styled from 'styled-components'
import {
  BOX_SHADOW_LIGHT,
  COLORS,
  FONT_SIZES,
  FONT_WEIGHTS,
  OFFSETS,
  BP,
} from '../../../misc/constants'
import { typography } from '../../../misc/typography'
import Link from '../../Link'

export const PostWrapper = styled.article`
  width: 53rem;
  max-width: 100%;
  box-shadow: ${BOX_SHADOW_LIGHT};
  display: flex;
  flex-direction: column;

  @media (max-width: ${BP[1200]}) {
    margin: ${OFFSETS[18]} ${OFFSETS[18]} ${OFFSETS[30]};
    &:not(:last-child) {
      margin-bottom: ${OFFSETS[30]};
    }
  }
`

export const TextWrapper = styled.div`
  padding: ${OFFSETS[24]};
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: baseline;
  min-height: 23rem;
  @media (max-width: ${BP[768]}) {
    padding: ${OFFSETS[20]};
  }
`

export const Title = styled.h4`
  ${typography(FONT_SIZES[16], FONT_WEIGHTS.SEMI_BOLD, COLORS.DARK)}
  flex-grow: 1;
`

export const Description = styled.p`
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.THIN, COLORS.DARK, '18px')}
`

export const ReadMore = styled(Link)`
  ${typography(FONT_SIZES[14], FONT_WEIGHTS.REGULAR, COLORS.ORANGE)}
`

export const Thumbnail = styled.img`
  object-fit: cover;
`