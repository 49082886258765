import { FONT_FAMILY } from "./constants";

export const typography = (
  size,
  weight,
  color,
  lineHeight = '1.4',
) => {
  return `
  font-family: ${FONT_FAMILY};
  color: ${color};
  font-weight: ${weight};
  line-height: ${lineHeight};
  ${
    size
      ? `
    font-size: ${size};
  `
      : ''
  }
`;
}

export const truncate = (width) => `
  width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
