import styled, { css } from 'styled-components'
import { transition } from '../../misc/utils'
import { typography } from '../../misc/typography'
import {
  OFFSETS,
  COLORS,
  FONT_SIZES,
  FONT_WEIGHTS,
  Z_INDEX,
  BP,
} from '../../misc/constants'

export const Wrapper = styled.div`
  position: relative;
  height: 13rem;
  width: 100%;
  max-width: 36rem;
  margin: 0 auto ${OFFSETS[40]};
`

export const Label = styled.label`
  padding: 4px;
  position: absolute;
  top: 0;
  left: 0;
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.REGULAR, COLORS.DARK_GREY)}
  ${transition(['transform', 'opacity'])};
  z-index: ${Z_INDEX.LEVEL_1};
  ${({ isVisible }) => isVisible
    ? css`
      transform: translateY(-100%);
      opacity: 1;  
    `
    : css`
      transform: translateY(0);
      opacity: 0;
  `}
`

const placeholderStyles = () => css`
  opacity: 1;
  ${typography(FONT_SIZES[14], FONT_WEIGHTS.REGULAR, COLORS.DARK_GREY, '1.6')};
`

export const commonInputStyles = () => css`
  ${typography(FONT_SIZES[16], FONT_WEIGHTS.REGULAR, COLORS.DARK)};
  color: ${COLORS.DARK};
  background: ${COLORS.WHITE};
  padding: ${OFFSETS[8]} ${OFFSETS[18]};
  border: 0.1rem solid ${COLORS.GREY};
  margin-bottom: ${OFFSETS[40]};
  width: 100%;
  max-width: 36rem;
  appearance: none;
  border-radius: 0;
  z-index: ${Z_INDEX.LEVEL_2};

  &:focus,
  &:focus-visible {
    border-color: ${COLORS.ORANGE};
    outline: none;
  }

  &::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  &::-moz-placeholder {
    ${placeholderStyles}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}
  }
  &:-moz-placeholder {
    ${placeholderStyles}
  }

  @media (max-width: ${BP[480]}) {
    margin-bottom: ${OFFSETS[24]};
  }
`

export const ErrorMessage = styled.div`
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.REGULAR, COLORS.RED)}
  position: absolute;
  bottom: 0;
  right: 0;
  transition: ${transition(['transform', 'opacity'])};
  z-index: ${Z_INDEX.LEVEL_1};
  padding: 4px;
  ${({ isVisible }) => isVisible
    ? css`
      transform: translateY(100%);
      opacity: 1;  
    `
    : css`
      transform: translateY(0);
      opacity: 0;
  `}
`

export const Textarea = styled.textarea`
  resize: none;
  height: 13rem;
  ${commonInputStyles};
`
