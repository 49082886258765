import styled, { keyframes } from 'styled-components'
import { Section } from '../layoutComponents'
import backgroundImageUrl from '../../images/hero/hero-background.jpg'
import backgroundWebpImageUrl from '../../images/hero/hero-background.webp'
import { OFFSETS, BP } from '../../misc/constants'
import { isWebpNotSupported } from '../../misc/utils'

const zoomIn = keyframes`
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${isWebpNotSupported() ? backgroundImageUrl : backgroundWebpImageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 67rem;
  width: 100%;
  animation: ${zoomIn} 15s ease 0s normal both infinite;

  @media (max-width: ${BP[480]}) {
    min-height: 40rem;
  }
`

export const HeroSection = styled(Section).attrs({
  'data-section': 'hero'
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
  min-height: 67rem;
  position: relative;

  @media (max-width: ${BP[480]}) {
    min-height: 40rem;
  }
`

export const LogoWrapper = styled.div`
  margin: ${OFFSETS[18]} ${OFFSETS[24]};
`