import React from 'react'
import { SOCIAL_URLS } from '../../../../misc/constants'
import Facebook from '../../../SVGs/Icons/Facebook'
import Instagram from '../../../SVGs/Icons/Instagram'
import Youtube from '../../../SVGs/Icons/Youtube'
import {
  Wrapper,
  SocialLink,
} from './Socials.styled'

export const SOCIAL_DATA = [{
  url: SOCIAL_URLS.FACEBOOK,
  icon: <Facebook />,
}, {
  url: SOCIAL_URLS.INSTAGRAM,
  icon: <Instagram />,
}, {
  url: SOCIAL_URLS.YOUTUBE,
  icon: <Youtube />,
}]

const Socials = () => (
  <Wrapper>
    {SOCIAL_DATA.map(({ url, icon }) => (
      <SocialLink
        href={url}
        key={url}
        target="_blank"
      >
        {icon}
      </SocialLink>
    ))}
  </Wrapper>
)

export default Socials