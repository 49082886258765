import styled from 'styled-components'
import drop1 from '../../../../images/footer/drop-1.png'
import drop2 from '../../../../images/footer/drop-2.png'
import drop3 from '../../../../images/footer/drop-3.png'
import { OFFSETS } from '../../../../misc/constants'
import { transition } from '../../../../misc/utils'

export const Wrapper = styled.div`
  display: flex;
`

export const SocialLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${OFFSETS[18]};
  opacity: 0.8;
  transform-origin: center center;
  ${transition(['transform', 'opacity'])};
  &:nth-child(1) {
    background-image: url(${drop1});
  }
  &:nth-child(2) {
    background-image: url(${drop2});
  }
  &:nth-child(3) {
    background-image: url(${drop3});
  }
  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }
  &:active {
    transform: scale(0.95);
  }
`

export const Icon = styled.img``