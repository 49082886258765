import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '../../../../misc/constants'

const Facebook = ({ fill = COLORS.WHITE }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 8.54746C0 12.7734 3.06921 16.2874 7.08333 17V10.8609H4.95833V8.5H7.08333V6.61088C7.08333 4.48588 8.45254 3.30579 10.3891 3.30579C11.0025 3.30579 11.6641 3.4 12.2775 3.49421V5.66667H11.1917C10.1525 5.66667 9.91667 6.18587 9.91667 6.84746V8.5H12.1833L11.8058 10.8609H9.91667V17C13.9308 16.2874 17 12.7741 17 8.54746C17 3.84625 13.175 0 8.5 0C3.825 0 0 3.84625 0 8.54746Z" fill={fill}/>
  </svg>
)

Facebook.propTypes = {
  fill: PropTypes.string,
}

Facebook.defaultProps = {
  fill: COLORS.WHITE,
}

export default Facebook