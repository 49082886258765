import { createGlobalStyle } from 'styled-components'
import { darken } from 'polished'
import {
  COLORS,
  FONT_FAMILY,
} from './constants'

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  ::selection {
    background: ${COLORS.ORANGE};
    color: ${COLORS.WHITE};
  }

  html {
    font-size: 62.5%;
  }
  html,
  body {
    /* height: 100%; */
  }

  body {
    background-repeat: no-repeat;
    background-color: ${COLORS.DARK};
    font-size: 1.6rem;
    line-height: 1.4;
    font-family: ${FONT_FAMILY};
    margin: 0;
  }

  a, button {
    &:focus {
      outline-style: dotted;
      outline-width: 0.2rem;
      outline-offset: 0.4rem;
      outline-color: ${darken(0.5, COLORS.WHITE)};
    }
  }

  h1, h2, h3, h4 {
    /* letter-spacing: 0.1rem; */
    margin: 0 0 1rem;
  }

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.8rem;
  }
  
  h4 {
    font-size: 2rem;
  }

  p {
    margin: 0 0 1rem;
    line-height: 1.75;
  }

  ol {
    line-height: 1.75;
    padding-left: 2rem;

    li {
      margin-bottom: 1rem;

      ol {
        list-style: lower-alpha;
        padding-left: 2rem;
      }
    }
  }
`
