import styled from 'styled-components'
import {
  BUTTON_COMMON_ACTIVE,
  BUTTON_COMMON_HOVER,
  BUTTON_PRIMARY_ACTIVE,
  BUTTON_PRIMARY_HOVER,
  BP,
} from '../../../misc/constants'
import { transition } from '../../../misc/utils'
import Button from '../../Button'

export const ButtonMobile = styled(Button)`
  @media (min-width: ${BP[768]}) {
    display: none;
  }
`

export const ButtonDesktop = styled(Button)`
  @media (max-width: ${BP[767]}) {
    display: none;
  }
`

export const ButtonGroup = styled.g`
  ${transition(['transform'])};
  transform-origin: center center;

  rect {
    ${transition(['fill'])};
  }

  &:hover {
    ${BUTTON_COMMON_HOVER()};

    rect {
      ${BUTTON_PRIMARY_HOVER()};
    }
  }

  &:active {
    ${BUTTON_COMMON_ACTIVE()};

    rect {
      ${BUTTON_PRIMARY_ACTIVE()};
    }
  }
`