import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Label,
  Input,
  ErrorMessage,
} from './InputWithFloatingLabel.styled'

export default function InputWithFloatingLabel({
  type,
  required,
  label,
  onChange,
  onBlur,
  hasError,
}) {
  const [inputValue, setInputValue] = useState('')

  function handleChange({
    target: { value }
  }) {
    setInputValue(value)
    onChange(value)
  }

  return (
    <Wrapper>
      <Label isVisible={inputValue !== ''}>
        {label}
      </Label>

      <Input
        placeholder={label}
        onChange={handleChange}
        onBlur={onBlur}
        type={type}
        required={required}
      />

      <ErrorMessage isVisible={hasError}>
        {type === 'email' && `Enter valid email`}
        {type === 'name' && `Name cannot be empty`}
      </ErrorMessage>
    </Wrapper>
  )
}

InputWithFloatingLabel.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool.isRequired,
}

InputWithFloatingLabel.defaultProps = {
  required: false,
}