import { css } from 'styled-components'
import { darken, rgba } from 'polished'

export const OUTER_CONTAINER_WIDTH = '160rem'
export const INNER_CONTAINER_WIDTH = '108rem'

export const FONT_FAMILY = 'Montserrat';

export const COLORS = {
  BLACK: '#000',
  GREY: '#ECECEC',
  DARK_GREY: '#BDBDBD',
  CHARCOAL: '#151515',
  SLIGHTLY_DARKER_DARK: '#1F2123',
  DARK: '#25282A',
  WHITE: '#FFF',
  ORANGE: '#EC5839',
  VIOLET: '#4F58AE',
  BLUE: '#50E6FF',
  YELLOW: '#FFAF60',
  RED: '#FF0000'
}

export const FONT_SIZES = {
  '10': '1rem',
  '12': '1.2rem',
  '14':'1.4rem',
  '16':'1.6rem',
  '18':'1.8rem',
  '20':'2rem',
  '28':'2.8rem',
  '36':'3.6rem',
  '48': '4.8rem',
  '64':'6.4rem',
};

export const FONT_WEIGHTS = {
  THIN: '400',
  REGULAR: '500',
  SEMI_BOLD: '600',
  BOLD: '700'
}

export const HEADING_LEVELS = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
};

export const Z_INDEX = {
  LEVEL_NEGATIVE: `-1`,
  LEVEL_0: `0`,
  LEVEL_1: `1`,
  LEVEL_2: `2`,
  LEVEL_3: `3`,
  LEVEL_4: `4`,
  LEVEL_5: `5`,
  LEVEL_6: `6`,
  LEVEL_7: `7`,
};

export const OFFSETS = {
  4: '0.4rem',
  8: '0.8rem',
  18: '1.8rem',
  20: '2.0rem',
  24: '2.4rem',
  30: '3.0rem',
  40: '4.0rem',
  48: '4.8rem',
  96: '9.6rem',
  192: '19.2rem',
};

export const TRANSITION_EASING = 'ease-in-out'
export const TRANSITION_DURATION = '0.2s'

export const BUTTON_HEIGHT = '4.6rem'
export const BUTTON_RADIUS = '5rem'
export const BUTTON_MIN_WIDTH = '25rem'
export const BUTTON_COMMON_HOVER = () => css`
  transform: scale(1.05) translateZ(0);
  cursor: pointer;
`
export const BUTTON_COMMON_ACTIVE = () => css`
  transform: scale(0.95) translateZ(0);
`

export const BUTTON_PRIMARY_HOVER = () => css`
  background: ${darken(0.05, COLORS.ORANGE)};
  fill: ${darken(0.05, COLORS.ORANGE)};
`
export const BUTTON_PRIMARY_ACTIVE = () => css`
  background: ${darken(0.1, COLORS.ORANGE)};
  fill: ${darken(0.1, COLORS.ORANGE)};
`

export const BUTTON_SECONDARY_HOVER = () => css`
  background: ${darken(0.05, COLORS.WHITE)};
  fill: ${darken(0.05, COLORS.WHITE)};
`
export const BUTTON_SECONDARY_ACTIVE = () => css`
  background: ${darken(0.1, COLORS.WHITE)};
  fill: ${darken(0.1, COLORS.WHITE)};
`

export const SOCIAL_URLS = {
  FACEBOOK: 'https://www.facebook.com/venturedevscom/',
  INSTAGRAM: 'https://www.instagram.com/venturedevs/',
  YOUTUBE: 'https://www.youtube.com/channel/UCc98D9qiYhSH4e0uPTl8XNg?view_as=subscriber',
}

export const BOX_SHADOW_LIGHT = `0 0.4rem 2rem 0.5rem ${rgba(COLORS.DARK, 0.1)}`
export const BOX_SHADOW_HEAVY = `0 0.9rem 3.4rem 0.5rem ${rgba(COLORS.DARK, 0.15)}`

export const MAILCHIMP_REGISTER_ENDPOINT = `https://venturedevs.us14.list-manage.com/subscribe/post?u=dc11b4e1fbbafbd003e4be308&amp;id=2e946faea6`

export const RESULT_CODE = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const BP = {
  320: '320px',
  480: '480px',
  767: '767px',
  768: '768px',
  769: '769px',
  1024: '1024px',
  1200: '1200px',
}

export const BORDER_RADIUS = `${OFFSETS[32]}`;

export const NAV_SCROLL_OFFSET = 70

export const ROUTES = {
  HOME: '/',
  CONSENTS: '/consents'
}

// eslint-disable-next-line
export const EMAIL_PATTERN = /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/