import React from 'react'
import { Event } from '..'
import { EventsSection } from './Events.styled'

import jakubAvatar from '../../images/speaker-avatars/jakub.jpg'
import mateuszAvatar from '../../images/speaker-avatars/mateusz.jpg'
import julianAvatar from '../../images/speaker-avatars/julian.jpg'
import filipAvatar from '../../images/speaker-avatars/filip.jpg'
import agnieszkaAvatar from '../../images/speaker-avatars/agnieszka.jpg'
import kamilAvatar from '../../images/speaker-avatars/kamil.jpg'

import jakubWebpAvatar from '../../images/speaker-avatars/jakub.webp'
import mateuszWebpAvatar from '../../images/speaker-avatars/mateusz.webp'
import julianWebpAvatar from '../../images/speaker-avatars/julian.webp'
import filipWebpAvatar from '../../images/speaker-avatars/filip.webp'
import agnieszkaWebpAvatar from '../../images/speaker-avatars/agnieszka.webp'
import kamilWebpAvatar from '../../images/speaker-avatars/kamil.webp'
import { isWebpNotSupported } from '../../misc/utils'

export const AGENDA = [{
  time: '15:00',
  text: 'We’ll send a link to registered participants',
}, {
  time: '18:00',
  text: 'Let the party start — presentation time!',
}, {
  time: 'After the presentation',
  text: 'Q&A',
}]

export const EVENTS = [{
  edition: 8,
  date: new Date('May 29, 2021 18:00:00'),
  title: 'GraphQL',
  description: `We will talk about what it is (and what it isn't), how it can benefit a project, and when it’s best not to use it. We will compare GraphQL with REST API, which will also show us what to pay attention to when selecting technology for a project.`,
  agenda: AGENDA,
  speaker: 'Jakub Buśkiewicz',
  speakerAvatarUrl: isWebpNotSupported() ? jakubAvatar : jakubWebpAvatar,
  speakerBio: 'Frontend developer/coach in love with music and mountains hiking, keen about new technologies and sharing knowledge. He is interested in psychology, electronic music and.. cooking. He loves baking/cooking, reading books, riding bike or hiking. Former trumpet player.',
  registerUrl: '',
  videoUrl: 'https://www.youtube.com/watch?v=oZ6F3aiCodc',
}, {
  edition: 7,
  date: new Date('March 25, 2021 18:00:00'),
  title: 'Teal, teal organizations, teal management',
  description: `More and more often these slogans are used in conversations about new management ways and cooperation between people. What distinguishes teal-managed companies? What are the results and why? We’ll take a closer look at the premises and draw some general conclusions for practical application in every company, in every team, and for every person.`,
  agenda: AGENDA,
  speaker: 'Mateusz Krawczyk',
  speakerAvatarUrl: isWebpNotSupported() ? mateuszAvatar : mateuszWebpAvatar,
  speakerBio: `Mateusz Krawczyk loves doing things differently and is always looking for new solutions. Builds products or services based on understanding of user needs and helps teams working smarter, not harder.
  Has +10 years of experience of working with project management and implementation of products onto market, as well as running own business.`,
  registerUrl: '',
  videoUrl: 'https://www.youtube.com/watch?v=tbO7xlnCr4Q',
}, {
  edition: 6,
  date: new Date('February 25, 2021 18:00:00'),
  title: 'The magic LOCK and how the HTTPS protocol works.',
  description: `During the meeting, we will talk about the magic LOCK, that is, how the HTTPS protocol works. Our speaker, Julian, ensures that the presentation will be useful for both juniors and seniors. And there will be some Math as well :)`,
  agenda: AGENDA,
  speaker: 'Julian Kwieciński',
  speakerAvatarUrl: isWebpNotSupported() ? julianAvatar : julianWebpAvatar,
  speakerBio: 'A full-stack developer, most fond of working in .NET and React. He is currently involved in project management and architecture as Team Lead at VentureDevs. Privately, a husband, a dad of two sons, and a Lego and Nintendo Switch enthusiast.',
  registerUrl: '',
  videoUrl: 'https://www.youtube.com/watch?v=MNI59BKXGHU',
}, {
  edition: 5,
  date: new Date('January 19, 2021 18:00:00'),
  title: 'Code persistence. Coupling and Cohesion in Python.',
  description: `A working code and some tests are not enough. Ideas can be programmed in various ways. In only half an hour you'll learn how to avoid a catastrophe and choose the right one, based on practical examples from Python.`,
  agenda: AGENDA,
  speaker: 'Filip Waligórski',
  speakerAvatarUrl: isWebpNotSupported() ? filipAvatar : filipWebpAvatar,
  speakerBio: 'Python programming enthusiast with experience in projects in the financial industry and social media. Interested in artificial intelligence, cybersecurity, and software architecture, in which he plans to obtain a certificate. He spends his free time actively - finds peace of mind in hiking, mountain biking, or motorcycle trips.',
  registerUrl: '',
  videoUrl: 'https://www.youtube.com/watch?v=e19s1dUuxyk',
}, {
  edition: 4,
  date: new Date('December 15, 2020 18:00:00'),
  title: 'How to avoid occupational burnout in the era of remote work.',
  description: `In the era of the pandemic, remote work has become a necessity - it is safer, cheaper, and more convenient. However, apart from the obvious advantages, it also carries an increased risk of burnout. That is why it is crucial to take care of your mental and emotional well-being. What are the statistics when it comes to occupational burnout? What are the reasons behind it? What are the symptoms? How to avoid it? I wish to tell you about this during the next VentureMeetup.`,
  agenda: AGENDA,
  speaker: 'Agnieszka Chudzicka',
  speakerAvatarUrl: isWebpNotSupported() ? agnieszkaAvatar : agnieszkaWebpAvatar,
  speakerBio: 'Art historian, child therapist, Scrum Master. At VentureDevs, I work as a Scrum Master and take care of the teams’ effectiveness, as well as good interpersonal relations and soft skills. What I care about the most is creating a friendly work environment in which people can achieve their full potential. I am interested in psychology, literature, art, and music. In my spare time, I play with my children, sew, and weave.',
  registerUrl: '',
  videoUrl: 'https://www.youtube.com/watch?v=ohsJV6pogNA',
}, {
  edition: 3,
  date: new Date('November 19, 2020 18:00:00'),
  title: 'Setting sail an IT team of one.',
  description: `How to escape shipwrecks and storms, avoid running aground, and, most importantly, deliver a valuable product.`,
  agenda: AGENDA,
  speaker: 'Kamil Kollman',
  speakerAvatarUrl: isWebpNotSupported() ? kamilAvatar : kamilWebpAvatar,
  speakerBio: 'Fullstack developer with a background in the humanities. Deals with art and Javascript, culture and code, philosophy and programming. Likes to talk a lot.',
  registerUrl: '',
  videoUrl: 'https://www.youtube.com/watch?v=snwLErSHC3Q',
}]

const Events = () => (
  <EventsSection>
    {EVENTS.map(item => <Event data={item} key={item.edition} />)}
  </EventsSection>
)

export default Events