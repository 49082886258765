import styled, { css } from 'styled-components'
import { BUTTON_COMMON_ACTIVE, BUTTON_COMMON_HOVER, BUTTON_PRIMARY_ACTIVE, BUTTON_PRIMARY_HOVER, BUTTON_SECONDARY_ACTIVE, BUTTON_SECONDARY_HOVER, COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../misc/constants'
import { BUTTON_VARIANTS } from './Button'
import { transition } from '../../misc/utils'
import { typography } from '../../misc/typography'

const buttonReset = () => css`
  background: transparent;
  padding: 0;
  border: 0;
`

const commonButtonStyles = ({
  cssCallback = () => css``,
  cssHoverCallback = () => css``,
  cssActiveCallback = () => css``,
}) => css`
  backface-visibility: hidden;
  ${transition(['background', 'transform'])};
  ${typography(FONT_SIZES[14], FONT_WEIGHTS.SEMI_BOLD, COLORS.WHITE)};
  transform-origin: center center;
  border-radius: 5rem;
  height: 4.6rem;
  min-width: 25rem;
  text-align: center;
  ${cssCallback}
  &:hover {
    ${BUTTON_COMMON_HOVER()}
    ${cssHoverCallback}
  }
  &:active {
    ${BUTTON_COMMON_ACTIVE()};
    ${cssActiveCallback}
  }
`

export const A = styled.a`
  ${({ variant }) => variant === BUTTON_VARIANTS.PRIMARY && css`
    ${commonButtonStyles({
      cssHoverCallback: () => BUTTON_PRIMARY_HOVER(),
      cssActiveCallback: () => BUTTON_PRIMARY_ACTIVE(),
    })};
    background: ${COLORS.ORANGE};
  `}
  ${({ variant }) => variant === BUTTON_VARIANTS.SECONDARY && css`
    ${commonButtonStyles({
      cssHoverCallback: () => BUTTON_SECONDARY_HOVER(),
      cssActiveCallback: () => BUTTON_SECONDARY_ACTIVE(),
    })};
    background: ${COLORS.WHITE};
    color: ${COLORS.DARK};
    border: 0.1rem solid ${COLORS.ORANGE};
  `}

  ${({ variant }) => variant === BUTTON_VARIANTS.GHOST && css`
    &:hover {
      cursor: pointer;
    }
  `};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Btn = styled.button`
  ${buttonReset()};
  ${({ variant }) => variant === BUTTON_VARIANTS.PRIMARY && css`
    ${commonButtonStyles({
      cssHoverCallback: () => BUTTON_PRIMARY_HOVER(),
      cssActiveCallback: () => BUTTON_PRIMARY_ACTIVE(),
    })};
    background: ${COLORS.ORANGE};
  `};
  ${({ variant }) => variant === BUTTON_VARIANTS.SECONDARY && css`
    ${commonButtonStyles({
      cssHoverCallback: () => BUTTON_SECONDARY_HOVER(),
      cssActiveCallback: () => BUTTON_SECONDARY_ACTIVE(),
    })};
    background: ${COLORS.WHITE};
    color: ${COLORS.DARK};
    border: 0.1rem solid ${COLORS.ORANGE};
  `};

  ${({ variant }) => variant === BUTTON_VARIANTS.GHOST && css`
    &:hover {
      cursor: pointer;
    }
  `};

  ${({ disabled }) => disabled && css`
    ${transition(['filter', 'opacity'])};
    filter: grayscale(1);
    opacity: 0.7;
    &:hover {
      transform: none;
      cursor: not-allowed;
    }
  `};

  ${({ disabled, variant }) => {
    if (disabled && variant === BUTTON_VARIANTS.PRIMARY) {
      return css`
        &:hover {
          background: ${COLORS.ORANGE};
        }
      `
    }

    if (disabled && variant === BUTTON_VARIANTS.SECONDARY) {
      return css`
        &:hover {
          background: ${COLORS.WHITE};
        }
      `
    }
  }};
`