/* eslint eqeqeq: "off" */
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { animate } from 'popmotion'
import { css } from 'styled-components'
import Styler from 'stylefire'
import {
  NAV_SCROLL_OFFSET,
  TRANSITION_DURATION,
  TRANSITION_EASING
} from './constants'

export const isBrowser = () => typeof window !== 'undefined'

export const isWebpNotSupported = () => {
  if (isBrowser) return false

  return (
    navigator.userAgent.indexOf('MSIE') != -1 ||
    navigator.userAgent.indexOf('Trident') != -1
  ) ||
  (
    navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.vendor.indexOf('Apple Computer, Inc.') != -1
  )
}

export const transition = (
  properties,
  duration = TRANSITION_DURATION,
  easing = TRANSITION_EASING
) => {
  const transition = properties
    .map(property => `
      ${property} 
      ${duration} 
      ${easing}
    `)
    .join(', ')

  return css`
    transition: ${transition};
  `
}

export function throttle(callback, delay) {
  callback._timeout = setTimeout(callback, delay)
  clearTimeout(callback._timeout)
}

export function debounce(callback, delay) {
  clearTimeout(callback._timeout)
  callback._timeout = setTimeout(callback, delay)
}

export function $(selector, scope = document) {
  return scope.querySelector(selector)
}

export function scrollTo(selector) {
  return scrollIntoView($(selector), {
    behavior: actions =>
      actions.forEach(({ el, top, left }) => {
        const styler = Styler(el)

        animate({
          from: el.scrollTop,
          to: top - NAV_SCROLL_OFFSET,
          type: 'keyframes',
          onUpdate: (top) => styler.set('scrollTop', top),
        })
      }),
    scrollMode: 'if-needed',
    block: 'start',
  })
}
