import styled from 'styled-components'
import { BP } from '../../misc/constants'
import { Section } from '../layoutComponents'

export const EventsSection = styled(Section).attrs({
  'data-section': 'events'
})`
  transform: translateY(-17rem);
  margin-bottom: -17rem;

  @media (max-width: ${BP[480]}) {
    transform: translateY(-10rem);
    margin-bottom: -10rem;
  }
`
