import styled from 'styled-components'
import { COLORS, FONT_SIZES, FONT_WEIGHTS, BP } from '../../../misc/constants'
import { typography } from '../../../misc/typography'

export const Wrapper = styled.div`
  width: 16rem;

  @media (max-width: ${BP[768]}) {
    width: unset;
  }
`

export const TextSmall = styled.p`
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.REGULAR, COLORS.DARK)}
  width: 16rem;

  @media (max-width: ${BP[768]}) {
    width: unset;
  }
`

export const TextTiny = styled.p`
  ${typography(FONT_SIZES[10], FONT_WEIGHTS.THIN, COLORS.DARK)}
`