import styled, { css } from 'styled-components'

const duration = '.2s'
const distance = 8
const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)'

export const commonLinkStyles = (color) => css`
  color: ${color};
  position: relative;
  text-decoration: none;
  padding-bottom: 8px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0; 
    right: 0;
    height: 2px;
    background-color: ${color};
  }
  &:before {
    opacity: 0;
    transform: translateY(-${distance}px);
    transition: transform 0s ${easeOutBack}, opacity 0s;
  }
  &:after {
    opacity: 0;
    transform: translateY(${distance / 2}px);
    transition: transform ${duration} ${easeOutBack}, opacity ${duration};
  }
  &:hover,
  &:focus {
    &:before,
    &:after {
      opacity: 1;
      transform: translateY(0);
    }
    &:before {
      transition: transform ${duration} ${easeOutBack}, opacity ${duration};
    }
    &:after {
      transition: transform 0s ${duration} ${easeOutBack}, opacity 0s ${duration};
    }
  }
`

export const Anchor = styled.a`
  ${({ color }) => color && commonLinkStyles(color)};
`