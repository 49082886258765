import styled, { keyframes } from 'styled-components'
import {
  BOX_SHADOW_HEAVY,
  COLORS,
  FONT_SIZES,
  FONT_WEIGHTS,
  INNER_CONTAINER_WIDTH,
  OFFSETS,
  BP,
} from '../../misc/constants'
import { typography } from '../../misc/typography'

export const EventWrapper = styled.div`
  position: relative;
  background-color: ${({ isUpcoming }) => isUpcoming
    ? COLORS.WHITE
    : COLORS.SLIGHTLY_DARKER_DARK
  };
  width: 100%;
  max-width: ${INNER_CONTAINER_WIDTH};
  box-shadow: ${BOX_SHADOW_HEAVY};
  margin-bottom: ${({ isUpcoming }) => isUpcoming
    ? OFFSETS[192]
    : OFFSETS[48]
  };

  @media (max-width: ${BP[480]}) {
    margin-bottom: ${({ isUpcoming }) => isUpcoming
      ? OFFSETS[96]
      : OFFSETS[30]
    };
  }
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BP[480]}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

export const Edition = styled.div`
  ${typography(FONT_SIZES[16], FONT_WEIGHTS.BOLD, COLORS.ORANGE)};
  text-transform: uppercase;

  @media (max-width: ${BP[480]}) {
    ${typography(FONT_SIZES[12], FONT_WEIGHTS.BOLD, COLORS.ORANGE)};
    margin: 0 0 ${OFFSETS[18]};
  }
`

export const TopWrapper = styled.div`
  padding: ${OFFSETS[24]} 0 ${OFFSETS[40]} ${OFFSETS[40]};

  @media (max-width: ${BP[480]}) {
    padding: ${OFFSETS[20]};
  }  
`

export const PastEventsHeader = styled.div`
  max-width: ${INNER_CONTAINER_WIDTH};
  width: 100%;
  text-align: left;
  margin-bottom: ${OFFSETS[40]};
`

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${OFFSETS[30]} ${OFFSETS[30]} ${OFFSETS[40]};
  background-color: ${({ isUpcoming }) => isUpcoming
    ? COLORS.GREY
    : COLORS.CHARCOAL
  };
  @media (max-width: ${BP[480]}) {
    align-items: flex-start;
    padding: ${OFFSETS[20]} ${OFFSETS[20]} ${OFFSETS[30]};
  }
`

export const Body = styled.div`
  padding-right: ${OFFSETS[40]};
  display: flex;

  @media (max-width: ${BP[768]}) {
    flex-direction: column;
    padding-right: unset;
  }
`

export const Description = styled.p`
  ${({ isUpcoming }) => {
    const textColor = isUpcoming ? COLORS.DARK : COLORS.WHITE
    return typography(FONT_SIZES[16], FONT_WEIGHTS.THIN, textColor, '1.75')
  }};
  margin-right: ${OFFSETS[30]};
  
  @media (max-width: ${BP[480]}) {
    margin: 0 0 ${OFFSETS[18]};
    font-size: ${FONT_SIZES[12]};
  }
`

export const PlayButton = styled.button`
`

export const RegisterBtnWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding-top: ${OFFSETS[8]};
`

const slideIn = keyframes`
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0)
    opacity: 1;
  }
`

export const ResponseWrapper = styled.div`
  background: ${COLORS.SLIGHTLY_DARKER_DARK};
  min-height: 29.2rem;
  display: flex;
  align-items: center;
`

export const ResponseText = styled.p`
  padding: 4.8rem 2.0rem;
  max-width: 80rem;
  margin: 0 auto;
  ${typography(FONT_SIZES[20], FONT_WEIGHTS.THIN, COLORS.WHITE)};
  animation-duration: 0.2s;
  animation-name: ${slideIn};

  @media (max-width: ${BP[480]}) {
    font-size: ${FONT_SIZES[14]};
    margin-bottom: ${OFFSETS[24]};
    padding: 2.0rem;
  }
`