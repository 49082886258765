import React from 'react'
import Spacer from '../../Spacer/Spacer'
import { Facebook, Youtube } from '../../SVGs/Icons'
import { COLORS, OFFSETS } from '../../../misc/constants'
import {
  TextSmall,
  TextTiny,
  Wrapper,
} from './Info.styled'

const Info = () => (
  <Wrapper>
    <TextSmall>The video transmission will be available*:</TextSmall>
    <Youtube fill={COLORS.DARK_GREY} />
    <Spacer width={OFFSETS[8]} />
    <Facebook fill={COLORS.DARK_GREY} />
    <TextTiny>* - A link will be sent to the registered users on the day of the event.</TextTiny>
  </Wrapper>
)

export default Info