import React from 'react'
import Heading from '../../Heading'
import { COLORS, HEADING_LEVELS } from '../../../misc/constants'
import { Wrapper } from './PastEventsHeader.styled'

const PastEventsHeader = () => (
  <Wrapper>
    <Heading
      level={HEADING_LEVELS.H1}
      color={COLORS.WHITE}
    >
      Past Events:
    </Heading>
  </Wrapper>
)

export default PastEventsHeader