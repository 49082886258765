import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Image } from './Avatar.styled'

const Avatar = ({
  url
}) => (
  <Wrapper>
    <Image {...{ url }} />
  </Wrapper>
)

Avatar.propTypes = {
  url: PropTypes.string.isRequired,
}

export default Avatar