import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from '../../../../misc/constants'
import { Svg } from '../base.styled'

const Calendar = ({ fill = COLORS.WHITE }) => (
  <Svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 7.25H3V8.75H4.5V7.25ZM7.5 7.25H6V8.75H7.5V7.25ZM10.5 7.25H9V8.75H10.5V7.25ZM12 2H11.25V0.5H9.75V2H3.75V0.5H2.25V2H1.5C0.6675 2 0.00749999 2.675 0.00749999 3.5L0 14C0 14.3978 0.158035 14.7794 0.43934 15.0607C0.720644 15.342 1.10218 15.5 1.5 15.5H12C12.825 15.5 13.5 14.825 13.5 14V3.5C13.5 2.675 12.825 2 12 2ZM12 14H1.5V5.75H12V14Z" fill={fill}/>
  </Svg>
)

Calendar.propTypes = {
  fill: PropTypes.string,
}

Calendar.defaultProps = {
  fill: COLORS.WHITE,
}

export default Calendar