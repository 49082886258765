import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { scrollTo } from '../../misc/utils'
import { isSmartphoneScreenWidth } from '../../misc/mobile'
import {
  Nav,
  Hashtag,
  NavbarWrapper,
  NavItem,
  NavToggle,
  Bar,
  InnerWrapper
} from './Navbar.styled'

export const NAV_ITEMS = [{
  name: 'Events',
  target: '[data-section="events"]'
}, {
  name: 'Cooperation',
  target: '[data-section="cooperation"]'
}, {
  name: 'Stay updated',
  target: '[data-section="stay-updated"]'
}, {
  name: 'You have to see it',
  target: '[data-section="gallery"]'
}, {
  name: 'Blog',
  target: '[data-section="blog"]'
}]

const BARS = new Array(4).fill(null)

export default function Navbar({ hasBackground }) {
  const [isOpen, setIsOpen] = useState(false)

  function handleToggleNav() {
    setIsOpen(prevState => !prevState)
  }

  function handleItemClick(target) {
    scrollTo(target)
    if (isSmartphoneScreenWidth) {
      handleToggleNav()
    }
  }

  return (
    <NavbarWrapper {...{ hasBackground }}>
      <InnerWrapper>
        <Hashtag>#venturemeetups</Hashtag>

        <NavToggle onClick={handleToggleNav}>
          {BARS.map((_, idx) => (
            <Bar isNavbarOpen={isOpen} key={idx} />
          ))}
        </NavToggle>

        <Nav {...{ isOpen }}>
          {NAV_ITEMS.map(({ name, target }) => (
            <NavItem
              key={name}
              {...{ isOpen }}
              onClick={() => handleItemClick(target)}
            >
              {name}
            </NavItem>
          ))}
        </Nav>
      </InnerWrapper>
    </NavbarWrapper>
  )
}

Navbar.propTypes = {
  hasBackground: PropTypes.bool.isRequired,
}