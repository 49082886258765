import React from 'react'
import PropTypes from 'prop-types'
import { COLORS, OFFSETS } from '../../../misc/constants'
import Spacer from '../../Spacer/Spacer'
import Calendar from '../../SVGs/Icons/Calendar'
import {
  Wrapper,
  StartHour,
} from './EventDate.styled'
import {
  formatDate,
  getHour
} from './helpers'

export default function EventDate({ date, isUpcoming }) {
  const calendarColor = isUpcoming ? COLORS.WHITE : COLORS.DARK_GREY

  return (
    <Wrapper {...{ isUpcoming }}>
      <Calendar fill={calendarColor} />
      <Spacer width={OFFSETS[8]} />
      {formatDate(date)}
      {isUpcoming && <StartHour>{getHour(date)}</StartHour>}
    </Wrapper>
  )
}

EventDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isUpcoming: PropTypes.bool.isRequired,
}
