import { transition } from '../../misc/utils'
import styled, { css } from 'styled-components'
import {
  Z_INDEX,
  COLORS,
  INNER_CONTAINER_WIDTH,
  FONT_SIZES,
  FONT_WEIGHTS,
  OFFSETS,
  BP,
} from '../../misc/constants'
import { typography } from '../../misc/typography'
import { commonLinkStyles } from '../Link/Link.styled'

export const InnerWrapper = styled.div`
  width: ${INNER_CONTAINER_WIDTH};
  max-width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media (max-width: ${BP[480]}) {
    height: 5rem;
  }
`

export const NavbarWrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${Z_INDEX.LEVEL_3};
  ${transition(['background-color'])};
  background-color: ${({ hasBackground }) => hasBackground
    ? COLORS.DARK
    : 'transparent'
  };

  @media (max-width: ${BP[480]}) {
    justify-content: flex-start;
  }
`

export const Hashtag = styled.span`
  ${typography(FONT_SIZES[20], FONT_WEIGHTS.BOLD, COLORS.WHITE)};
  z-index: ${Z_INDEX.LEVEL_3};
  margin-left: ${OFFSETS[18]};

  @media (max-width: ${BP[320]}) {
    ${typography(FONT_SIZES[16], FONT_WEIGHTS.BOLD, COLORS.WHITE)};
  }
`

export const Nav = styled.nav`
  ${transition(['transform'])};

  @media (min-width: ${BP[769]}) {
    margin-right: ${OFFSETS[18]};
  }

  @media (max-width: ${BP[768]}) {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    transform: translateX(${({ isOpen }) => isOpen ? 0 : 100}%);
    background: ${COLORS.DARK};
  }
`

export const NavItem = styled.button`
  background: transparent;
  border: 0;
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.REGULAR, COLORS.WHITE)};
  ${transition(['transform'])};
  padding: 0;
  ${commonLinkStyles(COLORS.WHITE)}

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${BP[768]}) {
    ${typography(FONT_SIZES[16], FONT_WEIGHTS.REGULAR, COLORS.WHITE)};
    margin-bottom: ${OFFSETS[48]};
    transform: translateX(${({ isOpen }) => isOpen ? 0 : 50}rem);
    &:nth-child(1) {
      transition-delay: 0.1s;
    }
    &:nth-child(2) {
      transition-delay: 0.15s;
    }
    &:nth-child(3) {
      transition-delay: 0.2s;
    }
    &:nth-child(4) {
      transition-delay: 0.25s;
    }
    &:nth-child(5) {
      transition-delay: 0.3s;
    }
  }

  @media (min-width: ${BP[769]}) {
    &:not(:last-child) {
      margin-right: ${OFFSETS[30]}
    }
  }
`

export const NavToggle = styled.button`
  z-index: ${Z_INDEX.LEVEL_3};
  position: absolute;
  height: 3rem;
  width: 3rem;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  top: ${OFFSETS[18]};
  right: ${OFFSETS[18]};

  @media (min-width: ${BP[769]}) {
    display: none;
  }

  @media (max-width: ${BP[480]}) {
    top: 1rem;
  }
`

export const Bar = styled.div`
  height: 0.2rem;
  border-radius: 0.3rem;
  width: 28px;
  background: ${COLORS.WHITE};
  position: absolute;
  left: 0;
  ${transition(['all'])};
  ${({ isNavbarOpen }) => isNavbarOpen
    ? css`
      &:nth-child(1),
      &:nth-child(4) {
        top: 50%;
        opacity: 0;
        transform: translateY(-50%) scale(0);
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%) rotate(45deg)
      }

      &:nth-child(3) {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg)
      }
    `
    : css`
      &:nth-child(1) {
        top: 25%;
        transform: translateY(-50%);
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(4) {
        top: 75%;
        transform: translateY(-50%);
      } 
    `
  }
`
