import styled, { keyframes } from 'styled-components'
import {
  BOX_SHADOW_HEAVY,
  COLORS,
  FONT_SIZES,
  FONT_WEIGHTS,
  OFFSETS,
  BP,
} from '../../misc/constants'
import { Section } from '../layoutComponents'
import formBackground from '../../images/stay-updated/form-background.svg'
import { typography } from '../../misc/typography'

export const StayUpdatedSection = styled(Section).attrs({
  'data-section': 'stay-updated'
})`
  background: ${COLORS.WHITE};
  padding: 0 ${OFFSETS[18]};

  @media (max-width: ${BP[480]}) {
    padding: ${OFFSETS[24]} ${OFFSETS[18]} 0;
  }
`

export const ElementsHolder = styled.div`
  position: relative;
  width: 120rem;
  height: 96rem;

  @media (max-width: ${BP[1024]}) {
    width: 100%;
    height: 70rem;
  }

  @media (max-width: ${BP[480]}) {
    width: auto;
    height: auto;
  }
`

export const BackgroundDecoration = styled.div`
  background-image: url(${formBackground});
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;

  @media (max-width: ${BP[480]}) {
    display: none;
  }
`

export const Form = styled.form`
  width: 86rem;
  height: 56rem;
  background: ${COLORS.WHITE};
  box-shadow: ${BOX_SHADOW_HEAVY};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8rem 11rem 11rem;
  text-align: center;

  @media (max-width: ${BP[1024]}) {
    width: 70%;
    height: 60%;
    padding: 4rem;
  }
  
  @media (max-width: ${BP[480]}) {
    position: relative;
    transform: none;
    top: unset;
    left: unset;
    width: auto;
    height: auto;
    padding: 4rem ${OFFSETS[24]};
  }
`

export const Text = styled.p`
  ${typography(FONT_SIZES[20], FONT_WEIGHTS.THIN, COLORS.DARK)};
  text-transform: uppercase;
  margin-bottom: ${OFFSETS[48]};

  @media (max-width: ${BP[480]}) {
    font-size: ${FONT_SIZES[14]};
    margin-bottom: ${OFFSETS[24]};
  }
`

const slideIn = keyframes`
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0)
    opacity: 1;
  }
`

export const ResponseText = styled.p`
  ${typography(FONT_SIZES[20], FONT_WEIGHTS.THIN, COLORS.ORANGE)};
  margin-bottom: ${OFFSETS[48]};
  animation-duration: 0.2s;
  animation-name: ${slideIn};

  @media (max-width: ${BP[480]}) {
    font-size: ${FONT_SIZES[14]};
    margin-bottom: ${OFFSETS[24]};
  }
`

export const Clause = styled.div`
  ${typography(FONT_SIZES[12], FONT_WEIGHTS.REGULAR, COLORS.DARK)};
  margin: 0 0 ${OFFSETS[40]};

  @media (max-width: ${BP[480]}) {
    margin: 0 0 ${OFFSETS[20]};
  }
`
