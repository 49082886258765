import React from 'react'
import { COLORS, OFFSETS } from '../../../../misc/constants'
import {
  Wrapper,
  Member,
  Name,
  Role,
  Phone,
  Mail,
} from './Members.styled'

import PhoneIcon from '../../../SVGs/Icons/Phone/Phone'
import MailIcon from '../../../SVGs/Icons/Mail/Mail'
import Spacer from '../../../Spacer/Spacer'

export const MEMBERS_DATA = [{
  name: 'JAGODA GRECZYCHO',
  role: 'organization and press contact',
  phone: '+48 570 531 800',
  mail: 'jagoda.greczycho@venturedevs.com',
}, {
  name: 'KRZYSZTOF SZENIAK',
  role: 'organization and speakers',
  phone: '+48 737 636 775',
  mail: 'krzysiek@venturedevs.com',
}, {
  name: 'ANIA KLARYS',
  role: 'recruitment and info about VentureDevs',
  phone: '+48 570 100 506',
  mail: 'anna.klarys@venturedevs.com',
}]

function formatPhone(phone) {
  return phone.split(' ').join('')
}

const Members = () => (
  <Wrapper>
    {MEMBERS_DATA.map(({ name, role, phone, mail }) => (
      <Member key={name}>
        <Name>{name}</Name>
        <Role>{role}</Role>
        <Phone
          color={COLORS.WHITE}
          to={`tel:${formatPhone(phone)}`}
        >
          <PhoneIcon />
          <Spacer width={OFFSETS[8]} />
          {phone}
        </Phone>
        <Mail
          color={COLORS.WHITE}
          to={`mailto:${mail}`}
        >
          <MailIcon />
          <Spacer width={OFFSETS[8]} />
          {mail}
        </Mail>
      </Member>
    ))}
  </Wrapper>
)

export default Members