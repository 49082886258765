import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { CooperationSection } from './Cooperation.styled'

const Cooperation = () => (
  <CooperationSection>
    <StaticImage
      src='../../images/cooperation/cooperation.png'
      alt='Cooperation'
      placeholder='blurred'
      width={1060}
    />
  </CooperationSection>
)

export default Cooperation