import React from 'react'
import { COLORS, HEADING_LEVELS } from '../../misc/constants'
import Heading from '../Heading'
import Members from './components/Members'
import Socials from './components/Socials'
import { Copyright, FooterSection } from './Footer.styled'

const Footer = () => (
  <FooterSection>
    <Heading
      level={HEADING_LEVELS.H1}
      color={COLORS.WHITE}
    >
      Any Questions?
    </Heading>
    <Members />
    <Socials />
    <Copyright>© 2020 all rights reserved</Copyright>
  </FooterSection>
)

export default Footer