import React from 'react'
import PropTypes from 'prop-types'
import { Highlight, Speaker, Text, Wrapper } from './Bio.styled'

const Bio = ({ speaker, speakerBio, isUpcoming }) => (
  <Wrapper>
    <Speaker {...{ isUpcoming }}>
      By <Highlight>{speaker}</Highlight>
    </Speaker>
    <Text {...{ isUpcoming }}>{speakerBio}</Text>
  </Wrapper>
)

Bio.propTypes = {
  speaker: PropTypes.string.isRequired,
  speakerBio: PropTypes.string.isRequired,
  isUpcoming: PropTypes.bool.isRequired,
}

export default Bio