import { typography } from '../../misc/typography'
import styled, { css } from 'styled-components'
import {
  BOX_SHADOW_HEAVY,
  BUTTON_COMMON_ACTIVE,
  BUTTON_COMMON_HOVER,
  COLORS,
  FONT_SIZES,
  FONT_WEIGHTS,
  OFFSETS,
  BP,
} from '../../misc/constants'
import { transition } from '../../misc/utils'
import Button from '../Button'

export const ArrowUp = styled(Button)`
  position: fixed;
  right: ${OFFSETS[20]};
  bottom: ${OFFSETS[20]};
  width: ${OFFSETS[40]};
  height: ${OFFSETS[40]};
  border-radius: 50%;
  background: ${COLORS.WHITE};
  box-shadow: ${BOX_SHADOW_HEAVY};
  ${transition(['transform', 'visibility'])};
  ${typography(FONT_SIZES[20], FONT_WEIGHTS.BOLD, COLORS.DARK)};
  ${({ isVisible }) => isVisible
    ? css`
      transform: scale(1);
      visibility: visible;
    `
    : css`
      transform: scale(0);
      visibility: hidden;
    `
  };

  &:hover {
    ${BUTTON_COMMON_HOVER};
  }

  &:active {
    ${BUTTON_COMMON_ACTIVE};
  }

  @media (max-width: ${BP[480]}) {
    right: 1rem;
    bottom: 1rem;
  }
`