import styled from 'styled-components'
import {
  BOX_SHADOW_LIGHT,
  COLORS,
  INNER_CONTAINER_WIDTH,
  OFFSETS,
  BP,
} from '../../misc/constants'
import { Section } from '../layoutComponents'

export const GallerySection = styled(Section).attrs({
  'data-section': 'gallery'
})`
  background: ${COLORS.WHITE};
  align-items: center;
`

export const PhotoGrid = styled.div`
  max-width: ${INNER_CONTAINER_WIDTH};
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 103rem;
  margin-bottom: ${OFFSETS[40]};

  @media (max-width: ${BP[1024]}) {
    padding: ${OFFSETS[24]} 0;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    height: unset;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
`

export const Photo = styled.img`
  width: calc(33% - 1rem);
  max-width: 35rem;
  margin-bottom: 1.5rem;
  flex-shrink: 0;

  @media (max-width: ${BP[1024]}) {
    scroll-snap-align: start;
    margin-right: ${OFFSETS[18]};
    box-shadow: ${BOX_SHADOW_LIGHT};
    width: calc(33% - 3rem);
  }

  @media (max-width: ${BP[480]}) {
    width: calc(100% - 5rem);
  }
`
