import styled from 'styled-components'
import { FONT_SIZES, FONT_WEIGHTS, BP } from '../../misc/constants'
import { typography } from '../../misc/typography'

export const H1 = styled.h1`
  ${({ color }) =>
    typography(
      FONT_SIZES[36],
      FONT_WEIGHTS.BOLD,
      color
    )}

  @media (max-width: ${BP[480]}) {
    ${({ color }) =>
      typography(
        FONT_SIZES[28],
        FONT_WEIGHTS.BOLD,
        color
      )}
  }
`

export const H2 = styled.h2`
  ${({ color }) =>
    typography(
      FONT_SIZES[28],
      FONT_WEIGHTS.SEMI_BOLD,
      color
    )}

  @media (max-width: ${BP[480]}) {
    ${({ color }) =>
      typography(
        FONT_SIZES[20],
        FONT_WEIGHTS.SEMI_BOLD,
        color
      )}
  }
`

export const H3 = styled.h3`
  ${({ color }) =>
    typography(
      FONT_SIZES[20],
      FONT_WEIGHTS.SEMI_BOLD,
      color
    )}

  @media (max-width: ${BP[480]}) {
    ${({ color }) =>
      typography(
        FONT_SIZES[16],
        FONT_WEIGHTS.SEMI_BOLD,
        color
      )}
  }
`
