export function getOrdinal(day) {
  switch (day % 10) {
    case 1:  return 'st'
    case 2:  return 'nd'
    case 3:  return 'rd'
    default: return 'th'
  }
}

export function getHour(date) {
  return new Intl.DateTimeFormat('default', {
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
  }).format(date)
}

export function formatDate(date) {
  const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ]
  return `${date.getDate()}${getOrdinal(date.getDate())} of ${MONTH_NAMES[date.getMonth()]} ${date.getFullYear()}`
}
