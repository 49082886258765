import React from 'react'
import PropTypes from 'prop-types'
import { A, Btn } from './Button.styled'

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  GHOST: 'ghost',
}

const Button = ({
  url,
  isLink = false,
  isBlank = false,
  variant = BUTTON_VARIANTS.PRIMARY,
  children,
  onClick,
  className,
  disabled
}) => isLink ? (
  <A
    {...{ className, variant, onClick }}
    href={url}
    target={isBlank ? '_blank' : '_self'}
  >
    {children}
  </A>
) : (
  <Btn {...{ className, variant, onClick, disabled }}>
    {children}
  </Btn>
)

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  url: PropTypes.string,
  isLink: PropTypes.bool,
  isBlank: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  url: null,
  isLink: false,
  isBlank: false,
  variant: BUTTON_VARIANTS.PRIMARY,
  onClick: () => null,
  className: '',
  disabled: false,
}

export default Button