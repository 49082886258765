import { StaticImage } from 'gatsby-plugin-image'
import React, { forwardRef } from 'react'
import { Background, HeroSection, LogoWrapper } from './Hero.styled'

const Hero = forwardRef((_, ref) => (
  <HeroSection ref={ref}>
    <Background />
    <LogoWrapper>
      <StaticImage
        src='../../images/hero/hero-foreground.png'
        alt='VentureMeetups logo'
        loading='eager'
        placeholder='blurred'
        width={865}
      />
    </LogoWrapper>
  </HeroSection>
))

export default Hero